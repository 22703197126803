import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CatalogueAddItems.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  Catalogue,
  CatalogueItem,
  Listing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";
import InvoicesEmbeddedListingsView from "./CatalogueEmbeddedLisingsView";
import InvoicesSearchBarAddItem from "./CatalogueListingsSearchBar";
import CatalogueNetworkUtils from "./NetworkUtils/CatalogueNetworkUtils";
import CatalogueEmbeddedLisingsView from "./CatalogueEmbeddedLisingsView";
import CatalogueListingsSearchBar from "./CatalogueListingsSearchBar";
import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  sub_category: string;
  catalogue_id: string;
  vendor_id: string;
}

function CatalogueAddItems() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { category, sub_category, catalogue_id } = useParams<RouteParams>();
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [selectedListings, setSelectedListings] = useState<Listing[]>([]);
  const [catalogueItems, setCatalogueItems] = useState<CatalogueItem[]>([]);
  const [selectedCatalogueItem, setSelectedCatalogueItem] =
    useState<CatalogueItem | null>(null);
  const [isItemPartOfInvoice, setIsItemPartOfInvoice] =
    useState<boolean>(false);
  const [currentCatalogue, setCurrentCatalogue] = useState<Catalogue | null>(
    null
  );
  const [modalShow, setModalShow] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const [requiredQuantity, setRequiredQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddToCatalogue, setShowAddToCatalogue] = useState(false);
  const [jsonData, setJsonData] = useState<any[] | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const history = useHistory();
  const location = useLocation();
  const cataloguesNetworkUtils = new CatalogueNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  useEffect(() => {
    const getCatalogueItemsByCatalogueId = async (catalogueId: number) => {
      try {
        const response = await cataloguesNetworkUtils.getCatalogueItems(
          catalogueId.toString()
        );
        // console.log(response.data);
        setCatalogueItems(response.data);
      } catch (error) {
        console.error("Error in getting invoice items by invoice id : ", error);
      }
    };

    if (currentCatalogue) {
      if (currentCatalogue.catalogue_status == "PUBLISHED") {
        history.push(`/catalogues/all/details/${currentCatalogue.id}`);
      } else {
        getCatalogueItemsByCatalogueId(currentCatalogue.id);
        handleSearch("MSG");
      }
    }
  }, [currentCatalogue]);

  useEffect(() => {
    if (!modalShow) {
      setRequiredQuantity(0);
      setIsItemPartOfInvoice(false);
      setSelectedListing(null);
      setSelectedCatalogueItem(null);
    }
  }, [modalShow]);

  useEffect(() => {
    let sku: string = "";
    if (selectedListing) {
      sku = selectedListing.sku_id;
    } else if (selectedCatalogueItem) {
      sku = selectedCatalogueItem.listing.sku_id;
    }

    if (sku != "") {
      const obj: CatalogueItem | undefined = catalogueItems.find(
        (catalogueItem) => catalogueItem.sku_id == sku
      );
      if (obj) {
        setIsItemPartOfInvoice(true);
        setRequiredQuantity(obj.moq);
      }
    }
  }, [selectedListing, selectedCatalogueItem]);

  useEffect(() => {
    const fetchCatalogueById = async (catalogueId: string) => {
      try {
        const response = await cataloguesNetworkUtils.getCatalogueById(
          catalogueId
        );
        // console.log(response.data);
        setCurrentCatalogue(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    fetchCatalogueById(catalogue_id);
  }, [catalogue_id]);

  const handleRequiredQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRequiredQuantity(parseInt(value));
  };

  const onCardClick = (currentListing: Listing) => {
    // console.log("card clicked: ", currentListing);
    // setModalShow(true);
    // setSelectedListing(currentListing);

    const index = selectedListings.findIndex(
      (obj) => obj.sku_id === currentListing.sku_id
    );
    if (index !== -1) {
      const updatedList = [...selectedListings];
      updatedList.splice(index, 1);
      setSelectedListings(updatedList);
    } else {
      selectedListings.push(currentListing);
    }

    const updatedListings = listings.map((listing: Listing) => {
      if (listing.sku_id === currentListing.sku_id) {
        // Assuming the value you want to update is "valueToUpdate"
        // Update the desired value here
        return {
          ...listing,
          isSelected: !listing.isSelected, // Update newValue to the desired value
        };
      }
      return listing;
    });
    setListings(updatedListings);
  };

  const handleOnClickAdd = () => {
    if (requiredQuantity <= 0) {
      toast.error("Please enter a non-zero quantity to add.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    addToCatalogue(selectedListing, selectedCatalogueItem, requiredQuantity);
    setModalShow(false);
  };

  const handleUpload = async () => {
    console.log(jsonData);
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const response = await cataloguesNetworkUtils.addItemToCatalogeInBulk(
        jsonData,
        catalogue_id
      );
      const updatedCatalogueItems: Array<CatalogueItem> = response.data;
      setCatalogueItems(updatedCatalogueItems);
      toast.success("Upload successful", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      // getFilesHistory();
      const fileInput = document.getElementById(
        "fileInput"
      ) as HTMLInputElement | null;
      if (fileInput) {
        fileInput.value = ""; // Clear the value
      }
      setFile(null);
      // setDownloadButtonClicked(false);
      console.log(response);
    } catch (error) {
      console.log("Printing error from handleUpload updated inventory.");
      console.error("Error:", error);
      // setDownloadButtonClicked(false);
      toast.error("There was an error uploading the file. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }

    console.log("handle upload clicked");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("outside");
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            // 'result' contains parsed CSV data
            // Convert CSV data to JSON
            // setJsonData(result.data);
            // jsonPayload = result.data;
            // console.log(jsonPayload);

            const validRows = result.data.filter((row: any) => {
              // Check if the row is not empty
              return Object.values(row).some(
                (value) => value !== null && value !== ""
              );
            });

            if (validRows.length === 0) {
              toast.error(
                "Error: The CSV file contains no valid rows. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
              return;
            }
            setJsonData(validRows);
            console.log(result.data);
          },
          header: true, // Treat the first row as header
          skipEmptyLines: true,
        });
      }

      // console.log(jsonPayload);
      console.log("setting file");
      setFile(selectedFile);
    }
  };

  const addToCatalogue = async (
    listing: Listing | null,
    catalogueItem: CatalogueItem | null,
    qty: number
  ) => {
    if (listing || catalogueItem) {
      const sku: string = listing
        ? listing.sku_id
        : catalogueItem
        ? catalogueItem.sku_id
        : "";
      if (sku == "") return;

      //check sku in catalogueItems
      const obj: CatalogueItem | undefined = catalogueItems.find(
        (catalogueItem) => catalogueItem.sku_id == sku
      );

      let newcatalogueItem: any;
      let isNewItem: boolean = true;
      if (obj) {
        obj.moq = qty;
        newcatalogueItem = obj;
        isNewItem = false;
      } else {
        newcatalogueItem = {
          catalogue_id: catalogue_id,
          sku_id: sku,
          moq: qty,
          created_by: userDetailsFromStore.user_details.userId,
        };
      }

      // try {
      //   const response = await cataloguesNetworkUtils.addNewItemToCatalogue(
      //     newcatalogueItem,
      //     catalogue_id
      //   );
      //   // console.log(response.data);
      //   if (isNewItem) {
      //     setCatalogueItems((prevList) => [...prevList, response.data]);
      //   } else {
      //     const updatedList = catalogueItems.map((catalogueItem, index) =>
      //       catalogueItem.sku_id === sku ? response.data : catalogueItem
      //     );
      //     setCatalogueItems(updatedList);
      //   }
      //   toast.success("Success.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     hideProgressBar: true,
      //   });
      //   // setCurrentPurchaseOrder(response.data);
      // } catch (error) {
      //   console.error(
      //     "Error in fetching vendor listings by vendor id :",
      //     error
      //   );
      //   toast.error("Error while adding, try again.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     hideProgressBar: true,
      //   });
      // }
    }
  };

  const oncatalogueItemClick = (catalogueItem: CatalogueItem) => {
    setModalShow(true);
    setSelectedCatalogueItem(catalogueItem);
    setRequiredQuantity(catalogueItem.moq);
  };

  const handleOnClickDelete = async () => {
    // if (selectedCatalogueItem || selectedListing) {
    //   const sku: string = selectedCatalogueItem
    //     ? selectedCatalogueItem.sku_id
    //     : selectedListing
    //     ? selectedListing.sku_id
    //     : "";
    //   const itemToDelete = {
    //     invoice_id: invoice_id,
    //     sku_id: sku,
    //   };
    //   try {
    //     const response =
    //       await cataloguesNetworkUtils.deleteItemFromPurchaseOrder(
    //         itemToDelete,
    //         invoice_id
    //       );

    //     const updatedList = catalogueItems.filter(
    //       (poItem, index) => poItem.item_id != sku
    //     );
    //     setCatalogueItems(updatedList);
    //     toast.success("Successfully deleted.", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       hideProgressBar: true,
    //     });
    //   } catch (error) {
    //     console.error(
    //       "Error in fetching vendor listings by vendor id :",
    //       error
    //     );
    //     toast.error("Error while deleting, try again.", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       hideProgressBar: true,
    //     });
    //   }
    // }
    setModalShow(false);
  };

  const publishCatalogue = async () => {
    try {
      const response = await cataloguesNetworkUtils.publishCatalogue(
        catalogue_id
      );
      if (currentCatalogue) {
        const updatedIvoice = {
          ...currentCatalogue,
          catalogue_status: "PUBLISHED",
        };
        setCurrentCatalogue(updatedIvoice);
      }
      toast.success("Published successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } catch (error) {
      console.error("Error in publishing invoice :", error);
      toast.error("Error while publishing, try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const republishInvoice = async () => {
    // try {
    //   const response = await cataloguesNetworkUtils.republishInvoice(
    //     invoice_id,
    //     userDetailsFromStore.user_details.userId
    //   );
    //   if (currentCatalogue) {
    //     const updatedIvoice = {
    //       ...currentCatalogue,
    //       status: "PENDING_PUBLISHED",
    //     };
    //     setCurrentCatalogue(updatedIvoice);
    //   }
    //   toast.success("Re-Published successfully", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     hideProgressBar: true,
    //   });
    // } catch (error) {
    //   console.error("Error in republishInvoice :", error);
    //   toast.error("Error while re-publishing, try again.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     hideProgressBar: true,
    //   });
    // }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(requiredQuantity);
      handleOnClickAdd();
    }
  };

  const handleSearch = (query: string) => {
    setListings([]);
    setSelectedListings([]);
    setIsLoading(true);
    if (query == "") search("MSG", 1);
    else search(query, 1);
  };

  const search = async (query: string, pageNumber: number, count?: number) => {
    try {
      const response = await listingNetworkUtil.getListingsBySearchKeyword(
        query,
        pageNumber,
        count ? count : 150
      );

      const newListings: Array<Listing> = response.data["content"];
      setListings((prevList) => [...prevList, ...newListings]);
      setIsLoading(false);
    } catch (error) {
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
    }
  };

  const onAddToCatalogueClick = async () => {
    if (selectedListings.length == 0) {
      toast.error("Failed! No items were selected.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    setShowAddToCatalogue(true);
    const skuStringArray = selectedListings.map((obj) => ({
      sku_id: obj.sku_id,
      catalogue_id: catalogue_id,
      moq: 10,
    }));
    console.log(skuStringArray);
    try {
      const response = await cataloguesNetworkUtils.addSkusToCatalogue(
        skuStringArray,
        catalogue_id
      );

      const updatedCatalogueItems: Array<CatalogueItem> = response.data;
      console.log(updatedCatalogueItems);
      setCatalogueItems(updatedCatalogueItems);
      setShowAddToCatalogue(false);
      toast.success("Success! Added to catalogue.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setSelectedListings([]);
      const updatedListings = listings.map((item) => {
        if (item.isSelected) {
          // Update the key value to false if it is currently true
          return { ...item, isSelected: false };
        }
        return item;
      });
      setListings(updatedListings);
    } catch (error) {
      console.log("Printing error from ListingsHome");
      console.error("Error:", error);
      toast.error("Failed! Unable to add to catalogue.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowAddToCatalogue(false);
    }
  };

  const handleSampleDownload = async () => {
    try {
      jsonexport(
        [{ sku_id: "sku_1" }, { sku_id: "sku_2" }],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = currentCatalogue
            ? currentCatalogue.id + "_sample.csv"
            : "catalogue_details.csv";
          // Simulate a click to trigger the download
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        {currentCatalogue && (
          <div className="main-container">
            <div className="vendor-action-container">
              <div className="vendor-basics vendors-info-card add-items-heading">
                {currentCatalogue.catalogue_status == "PENDING" ? (
                  <div>
                    <div>Add Items to Catalogue - [ {catalogue_id} ]</div>
                    <Container className="mb-4">
                      <Row className="single-row pt-4 pl-4 pb-4 mt-4">
                        <Col>
                          <div className="upload-snapshot-container">
                            <h6>Upload csv to create instant catalogue</h6>
                            <div>
                              <p
                                className="download-sample-link"
                                onClick={handleSampleDownload}
                              >
                                [Download Sample File]
                              </p>
                            </div>
                            <div className="upload-controls">
                              <input
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv"
                                id="fileInput"
                              />

                              <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={() => {
                                  handleUpload();
                                }}
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : (
                  <div>Modify Catalogue [ {catalogue_id} ]</div>
                )}
              </div>
              {catalogueItems.length > 0 && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Catalogue Items</h4>
                    <div className="vendor-details-button-container">
                      {currentCatalogue.catalogue_status == "PENDING" && (
                        <div>
                          <button type="button" onClick={publishCatalogue}>
                            Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                      {currentCatalogue.catalogue_status ==
                        "MARKED_MODIFICATION" && (
                        <div>
                          <button type="button" onClick={republishInvoice}>
                            Re-Publish
                          </button>
                          {/* <PDFDownloadLink
                            document={<PurchaseOrderPDF poItems={poItems} />}
                            fileName="test"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-1-cells">
                    {catalogueItems.length != 0 && (
                      <div className="grid-2-cells">
                        <Alert variant="light" className="vendor-info">
                          <div>
                            <b>Total SKUs : </b>{" "}
                            {catalogueItems.length.toString()}
                          </div>
                        </Alert>
                      </div>
                    )}

                    {catalogueItems.map((catalogueItem, index) => {
                      return (
                        <div
                          className="catalogue-added-item-card"
                          key={index}
                          onClick={() => oncatalogueItemClick(catalogueItem)}
                        >
                          <div>
                            <b>{catalogueItem.sku_id}</b>
                          </div>
                          <div className="last-modified">
                            {DateTimeUtils.formatDateTime(
                              catalogueItem.updated_at
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {currentCatalogue && (
                <div className="vendor-basics vendors-info-card">
                  <div className="vendors-view-card-title">
                    <h4 className="vendor_id">Catalogue Details</h4>
                  </div>
                  <div className="grid-1-cells">
                    <div className="grid-item-details-card">
                      <label>Catalogue Status</label>
                      {currentCatalogue.catalogue_status == "PENDING" && (
                        <Badge className="mt-2 mr-2 ml-2 mb-2" bg="danger">
                          {currentCatalogue.catalogue_status}
                        </Badge>
                      )}

                      {/* <input
                      type="text"
                      name="po_status"
                      value={currentCatalogue.invoice_status}
                      disabled={true}
                    /> */}
                    </div>
                    <div className="grid-item-details-card">
                      <label>Catalogue Id</label>
                      <input
                        type="text"
                        name="invoice_id"
                        value={currentCatalogue.id}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Catalogue Name</label>
                      <input
                        type="text"
                        name="catalogue_name"
                        value={currentCatalogue.catalogue_name}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Catalogue Description</label>
                      <input
                        type="text"
                        name="catalogue_description"
                        value={currentCatalogue.catalogue_description}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Catalogue Type</label>
                      <input
                        type="text"
                        name="catalogue_type"
                        value={currentCatalogue.catalogue_type}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Created By</label>
                      <input
                        type="text"
                        name="created_by"
                        value={currentCatalogue.created_by}
                        disabled={true}
                      />
                    </div>
                    <div className="grid-item-details-card">
                      <label>Date Created</label>
                      <input
                        type="text"
                        name="date_created"
                        value={DateTimeUtils.formatDateTime(
                          currentCatalogue.created_at
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="invoice-listings-container">
              <div className="vendor-listings-search-bar-container ml-4">
                <CatalogueListingsSearchBar
                  onSearch={handleSearch}
                ></CatalogueListingsSearchBar>
                <Button
                  variant="secondary"
                  className="btn-add-to-catalogue ml-2"
                  onClick={onAddToCatalogueClick}
                >
                  Add to Catalogue
                </Button>
              </div>

              {isLoading ? (
                <div className="invoice-skus-spinner-container">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <div className="listings-container">
                  {listings.length != 0 && (
                    <div className="all-listings-container">
                      <CatalogueEmbeddedLisingsView
                        listings={listings}
                        onCardClick={onCardClick}
                      />
                    </div>
                  )}
                  {listings.length == 0 && (
                    <div className="no-items-view">Nothing to Display</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {showAddToCatalogue && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Adding to Catalogue, Please Wait..
        </div>
      )}
    </div>
  );
}

export default CatalogueAddItems;
