import React, { useEffect, useState } from "react";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppDispatch } from "../../state";
import { useAppSelector } from "../../state/hooks";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Header from "../Header";
import CompanyPurchaseOrdersConsignmentDetails from "./CompanyPurchaseOrdersConsignmentDetails";
import CompanyPurchaseOrdersConsignmentDocuments from "./CompanyPurchaseOrdersConsignmentDocuments";
import CompanyPurchaseOrdersConsignmentLogs from "./CompanyPurchaseOrdersConsignmentLogs";
import { ArrowLeft } from "react-bootstrap-icons";

interface RouteParams {
  po_id: string;
  consignment_id: string;
  operation: string;
}

function CompanyPurchaseOrdersConsignmentDetailsSubHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const { operation, po_id, consignment_id } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(operation);
  const handleItemClick = (item: string) => {
    if (item == "goBack") {
      // history.goBack();
      history.replace(`/incomingPos/info/details/${po_id}`);
      return;
    }
    setSelectedItem(item);
    history.replace(
      `/incomingPos/info/consignmentDetails/${po_id}/${consignment_id}/${item}`
    );
  };

  useEffect(() => {
    setSelectedItem(operation);
  }, [operation]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item `}
                onClick={() => handleItemClick("goBack")}
              >
                <ArrowLeft
                  size={15}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "details" ? "active" : ""
                }`}
                onClick={() => handleItemClick("details")}
              >
                Consignment Details
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "documents" ? "active" : ""
                }`}
                onClick={() => handleItemClick("documents")}
              >
                Consignment Documents
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "logs" ? "active" : ""
                }`}
                onClick={() => handleItemClick("logs")}
              >
                Consignment Logs
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem === "details" && (
          <CompanyPurchaseOrdersConsignmentDetails />
        )}

        {selectedItem === "documents" && (
          <CompanyPurchaseOrdersConsignmentDocuments />
        )}

        {selectedItem === "logs" && <CompanyPurchaseOrdersConsignmentLogs />}
      </div>
    </div>
  );
}

export default CompanyPurchaseOrdersConsignmentDetailsSubHome;
