import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/LabelsCompany.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  Catalogue,
  CatalogueItem,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderItemDetails,
  IncomingPurchaseOrderItemDetailsHasError,
  IncomingPurchaseOrderItemDetailsHasErrorMessages,
  LabelData,
  LabelDataV2,
  Listing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  SkuValidationAndExtractionObject,
  SkuValidationObject,
  SkuValidationResponseDataObject,
  SkuValidationResponseObject,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";

import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import LabelsNetworkUtils from "./NetworkUtils/LabelsNetworkUtils";
import CompanyPurchaseOrderLabelUtils from "../CompanyPurchaseOrders/LabelUtils/CompanyPurchaseOrderLabelUtils";

interface RouteParams {
  po_id: string;
}

function LabelsCompany() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const labelsNetworkUtils = new LabelsNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [currentSkuValidationResponseObject, setSkuValidationResponseObject] =
    useState<SkuValidationResponseObject>();
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);

  const [finalData, setFinalData] = useState<LabelDataV2[]>([]);
  const [finalDataHasError, setFinalDataHasError] = useState<
    IncomingPurchaseOrderItemDetailsHasError[]
  >([]);
  const [finalDataMessages, setFinalDataMessages] = useState<
    IncomingPurchaseOrderItemDetailsHasErrorMessages[]
  >([]);

  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [uploadButtonActive, setUploadButtonActive] = useState<boolean>(false);
  const [uploadButtonClicked, setUploadButtonClicked] =
    useState<boolean>(false);

  const mapToIncomingPurchaseOrderItemDetails = async (data: any[]) => {
    const incomingPurchaseOrderItemDetails: LabelDataV2[] = [];
    data.forEach((item) => {
      incomingPurchaseOrderItemDetails.push({
        barcode: item.barcode,
        marketedBy: item.marketedBy,
        manufacturedBy: item.manufacturedBy,
        title: item.title,
        dateOfManufacture: item.dateOfManufacture,
        color: item.color,
        brand: item.brand,
        material: item.material,
        netQuantity: item.netQuantity,
        productDimension: item.productDimension,
        oneSetContains: item.oneSetContains,
        modelNumber: item.modelNumber,
        mrp: item.mrp,
        countryOfOrign: item.countryOfOrigin,
        styleId: item.styleId,
        labelCount: item.labelCount,
      });
    });

    setFinalData(incomingPurchaseOrderItemDetails);
  };

  const handleSampleDownload = async () => {
    try {
      jsonexport(
        [
          {
            barcode: "",
            marketedBy: "",
            manufacturedBy: "",
            title: "",
            dateOfManufacture: "",
            color: "",
            brand: "",
            material: "",
            netQuantity: "",
            productDimension: "",
            oneSetContains: "",
            modelNumber: "",
            mrp: "",
            countryOfOrigin: "",
            styleId: "",
            labelCount: "",
          },
        ],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `eCraftZap-label-date-template.csv`;
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const expectedHeaders = [
      "barcode",
      "marketedBy",
      "manufacturedBy",
      "title",
      "dateOfManufacture",
      "color",
      "brand",
      "material",
      "netQuantity",
      "productDimension",
      "oneSetContains",
      "modelNumber",
      "mrp",
      "countryOfOrigin",
      "styleId",
      "labelCount",
    ];
    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              const validRows = result.data.filter((row: any) => {
                // Check if the row is not empty
                return Object.values(row).some(
                  (value) => value !== null && value !== ""
                );
              });

              if (validRows.length === 0) {
                toast.error(
                  "Error: The CSV file contains no valid rows. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return;
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToIncomingPurchaseOrderItemDetails(validRows);
              console.log(result.data);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header
          skipEmptyLines: true,
        });
      }

      // console.log(jsonPayload);
      // setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    setUploadButtonClicked(true);
  };

  const handleLabelCreation = async () => {
    console.log("here");
    const labelHeadings: LabelData = {
      barcode: "NA",
      marketedBy: "Marketed By/For Consumer Complaints",
      manufacturedBy: "Manufactured By",
      title: "Title",
      dateOfManufacture: "Date of Mfg.",
      color: "Color",
      brand: "Brand",
      material: "Material",
      netQuantity: "Net Quantity",
      oneSetContains: "1 Set Contains",
      productDimension: "Product Dimension",
      modelNumber: "Model Number",
      mrp: "M.R.P(INR)",
      countryOfOrign: "Country of Origin",
      styleId: "Style Id",
    };

    CompanyPurchaseOrderLabelUtils.generateDmartLabels70By40_v3(
      finalData,
      labelHeadings
    );
  };

  return (
    <div className="overlay">
      <Header />
      <div className="upload-purchase-order-details-container">
        {showLoadingPage ? (
          <div className="loading-page">
            <Spinner animation="grow" />
            Loading, please wait...
          </div>
        ) : !uploadButtonClicked ? (
          <div className="upload-incoming-po-container">
            <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
              <div className="upload-snapshot-container">
                <div className="upload-snapshot-heading-text">
                  Upload label requirement, in the provided format
                </div>
                <div>
                  <p
                    className="download-sample-link"
                    onClick={handleSampleDownload}
                  >
                    [Download Sample File]
                  </p>
                </div>
                <div className="upload-controls">
                  <input
                    className="upload-snapshot-button"
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                    id="fileInput"
                  />

                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      handleUpload();
                    }}
                    disabled={!uploadButtonActive}
                    className={
                      !uploadButtonActive
                        ? "upload-button disabled-hover"
                        : "upload-button"
                    }
                    style={{
                      cursor: !uploadButtonActive ? "not-allowed" : "pointer",
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="po-items-container">
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th>barcode</th>
                  <th>marketedBy</th>
                  <th>manufacturedBy</th>
                  <th>title</th>
                  <th>dateOfManufacture</th>
                  <th>color</th>
                  <th>brand</th>
                  <th>material</th>
                  <th>netQuantity</th>
                  <th>productDimension</th>
                  <th>oneSetContains</th>
                  <th>modelNumber</th>
                  <th>mrp</th>
                  <th>countryOfOrigin</th>
                  <th>styleId</th>
                  <th>labelCount</th>
                </tr>
              </thead>
              <tbody>
                {finalData.map((labelItem, index) => {
                  return (
                    <tr className="catalogues-search-bar-row" key={index}>
                      <td>{index + 1}</td>
                      <td>{labelItem.barcode}</td>
                      <td>{labelItem.marketedBy}</td>
                      <td>{labelItem.manufacturedBy}</td>
                      <td>{labelItem.title}</td>
                      <td>{labelItem.dateOfManufacture}</td>
                      <td>{labelItem.color}</td>
                      <td>{labelItem.brand}</td>
                      <td>{labelItem.material}</td>
                      <td>{labelItem.netQuantity}</td>
                      <td>{labelItem.productDimension}</td>
                      <td>{labelItem.oneSetContains}</td>
                      <td>{labelItem.modelNumber}</td>
                      <td>{labelItem.mrp}</td>
                      <td>{labelItem.countryOfOrign}</td>
                      <td>{labelItem.styleId}</td>
                      <td>{labelItem.labelCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Button
              size="sm"
              variant="outline-primary"
              onClick={handleLabelCreation}
              className="upload-po-final-data mt-4 mb-4"
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default LabelsCompany;
