import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersAllPurchaseOrders.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  POSkuLevelReportObject,
  PacksAndCombos,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { isGeneratorFunction } from "util/types";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useAppSelector } from "../../state/hooks";
import { Filter, FilterSquareFill } from "react-bootstrap-icons";
import EscapeKeyListener from "../EscapeKeyListener";
import jsonexport from "jsonexport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompanyPurchaseOrderPendingDispatchMultiPDF from "./CompanyPurchaseOrderPendingDispatchMultiPDF";

function CompanyPurchaseOrdersWIPPurchaseOrders() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  let pageNumber = 1;

  const [allIncomingPurchaseOrders, setAllIncomingPurchaseOrders] = useState<
    IncomingPurchaseOrderDataObject[]
  >([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);
  const [searchQueryLocal, setSearchQueryLocal] = useState<string>("");
  const [searchQueryGlobal, setSearchQueryGlobal] = useState<string>("");
  const [isPoNumberFilterVisible, setIsPoNumberFilterVisible] = useState(false); // State for filter visibility
  const [downloadPendencyPDF, setDownloadPendencyPDF] = useState(false);
  const [
    isAddWarehouseInventoryColumnChecked,
    setIsAddWarehouseInventoryColumnChecked,
  ] = useState<boolean>(true);

  const [poSkuLevelReportObjectList, setPoSkuLevelReportObjectList] = useState<
    POSkuLevelReportObject[]
  >([]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQueryLocal(e.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchQueryGlobal(searchQueryLocal);
      handleSearch(searchQueryLocal);
      setIsPoNumberFilterVisible(false);
    }
  };

  const applyPONumberFilter = () => {
    setSearchQueryGlobal(searchQueryLocal);
    handleSearch(searchQueryLocal);
    setIsPoNumberFilterVisible(false);
  };

  const getAllWIPPurchaseOrdersPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllWIPPurchaseOrdersPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          28
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllIncomingPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching catalogues :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getAllWIPPurchaseOrdersPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getAllWIPPurchaseOrdersPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getAllWIPPurchaseOrdersPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const handleShowWarehouseInventoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAddWarehouseInventoryColumnChecked(event.target.checked); // Update state based on the checkbox value
  };

  const handleCheckBoxChangeForAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedSelectAll(event.target.checked); // Update state based on the checkbox value
    if (event.target.checked) {
      setCheckedCount(allIncomingPurchaseOrders.length);
    } else {
      setCheckedCount(0);
    }

    setAllIncomingPurchaseOrders((prevItems) =>
      prevItems.map((item, i) => ({
        ...item,
        ["is_selected"]: event.target.checked,
      }))
    );
  };

  const handleCheckBoxChangeAtIndex = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) setCheckedCount(checkedCount + 1);
    else {
      setCheckedCount(checkedCount - 1);
      if (checkedCount - 1 == 0 && isCheckedSelectAll) {
        setIsCheckedSelectAll(false);
      }
    }

    setAllIncomingPurchaseOrders((prevItems) =>
      prevItems.map((item, i) =>
        i == index ? { ...item, ["is_selected"]: !item.is_selected } : item
      )
    );
  };

  const removeSelectedPOsFromWIP = async () => {
    setShowLoadingPage(true);
    const removalList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.removeSelectedPOsFromWIP(
          removalList,
          userDetailsFromStore.user_details.userId
        );
      window.location.reload();
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in removeSelectedPOsFromWIP : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const clearPoNumberFilter = () => {
    // setDeliveryLocationFilterList((prevItems) =>
    //   prevItems.map((item) => ({
    //     ...item, // Spread the existing properties of the object
    //     is_selected_local: item.is_selected_global, // Update `is_selected_local` with the value of `is_selected_global`
    //   }))
    // );

    setIsPoNumberFilterVisible(false);
    setSearchQueryLocal(searchQueryGlobal);
  };

  const resetPoNumberFilter = () => {
    setIsPoNumberFilterVisible(false);
    setSearchQueryLocal("");
    setSearchQueryGlobal("");
    handleSearch("");
  };

  const getAvailableQuantityForMulti = (
    packsAndCombos: PacksAndCombos[]
  ): number => {
    // console.log(listings);
    let available_quantity = 10000000;
    packsAndCombos.forEach((packsAndCombo, index) => {
      available_quantity = Math.min(
        available_quantity,
        packsAndCombo.listing.available_quantity
      );
    });

    return available_quantity == 10000000 ? 0 : available_quantity;
  };

  const downloadCurrentViewAsSpreadSheet = async () => {
    try {
      // itemsToExport.push()
      const transformedItems = allIncomingPurchaseOrders
        .filter((item) => item.is_selected)
        .map((singleItem, index) => {
          return {
            sr_no: index + 1,
            po_number: singleItem.po_number,
            po_type: singleItem.po_type,
            buyer_name: singleItem.company_name,
            delivery_location: singleItem.delivery_city,
            po_status: singleItem.calculated_po_status,
            is_wip: singleItem.is_wip,
            sku_count: singleItem.analytics_object.sku_count,
            total_demand: singleItem.analytics_object.total_demand,
            total_dispatched: singleItem.analytics_object.total_dispatched,
            total_packed: singleItem.analytics_object.total_packed,
            total_pending: singleItem.analytics_object.total_pending,
            quantity_fill_rate: singleItem.analytics_object.quantity_fill_rate,
            sku_fill_rate: singleItem.analytics_object.sku_fill_rate,
            total_consignments: singleItem.analytics_object.total_consignments,
            total_boxes_dispatched:
              singleItem.analytics_object.boxes_dispatched,
            total_boxes_packed: singleItem.analytics_object.boxes_packed,
            po_issue_date: DateTimeUtils.formatDateOnly(
              singleItem.po_issue_date
            ),
            po_expiry_date: DateTimeUtils.formatDateOnly(
              singleItem.expiry_date
            ),
            po_addition_date: DateTimeUtils.formatDateTime(
              singleItem.created_at
            ),
            po_added_by: singleItem.created_by,
          };
        });

      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "po_level_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from download current view.");
      console.error("Error:", error);
    }
  };

  const sortListings = (
    array: IncomingPurchaseOrderListingDetailsDataObject[]
  ): IncomingPurchaseOrderListingDetailsDataObject[] => {
    return [...array].sort((a, b) => {
      const diffA = a.demand - a.reserved_quantity - a.dispatched_quantity; //a.pending;
      const diffB = b.demand - b.reserved_quantity - b.dispatched_quantity; //b.pending;
      return diffB - diffA; // descending order
    });
  };

  const downloadSKULevelReportAsSpreadsheet = async (
    skuLevelReportObjectList: POSkuLevelReportObject[]
  ) => {
    const finalTransformedItems: any[] = [];
    try {
      skuLevelReportObjectList.forEach((currentPurchaseOrder, ind) => {
        const transformedItems = sortListings(
          currentPurchaseOrder.listings
        ).map((singleItem) => {
          return {
            buyer_name: currentPurchaseOrder.company.company_name,
            po_number: singleItem.po_number,
            po_issue_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.po_issue_date
            ),
            po_expiry_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.expiry_date
            ),
            po_addition_date: DateTimeUtils.formatDateOnly(
              currentPurchaseOrder.created_at
            ),
            po_type: currentPurchaseOrder.po_type,
            delivery_location: currentPurchaseOrder.delivery_city,
            po_secondary_sku: singleItem.po_secondary_sku,
            master_sku: singleItem.master_sku,
            inventory_sku_id: singleItem.inventory_sku_id,
            pack_combo_sku_id: singleItem.pack_combo_sku_id,
            sku_type: singleItem.sku_type,
            company_code_primary: singleItem.company_code_primary,
            company_code_secondary: singleItem.company_code_secondary,
            title: singleItem.title,
            mrp: singleItem.mrp,
            rate_without_tax: singleItem.rate_without_tax,
            tax_rate: singleItem.tax_rate,
            hsn: singleItem.hsn_code,
            size: singleItem.size,
            color: singleItem.color,
            ops_tag: singleItem.listing ? singleItem.listing.ops_tag : "",
            warehouse_quantity:
              singleItem.sku_type === "MULTI"
                ? getAvailableQuantityForMulti(singleItem.multi_listings)
                : singleItem.listing
                ? singleItem.listing.available_quantity
                : 0,
            demand: singleItem.demand,
            packed: singleItem.reserved_quantity,
            dispatched: singleItem.dispatched_quantity,
            pending:
              singleItem.demand -
              singleItem.reserved_quantity -
              singleItem.dispatched_quantity,
            fill_rate_percent:
              Math.round(
                ((singleItem.reserved_quantity +
                  singleItem.dispatched_quantity) /
                  singleItem.demand) *
                  100 *
                  100
              ) / 100,
          };
        });

        finalTransformedItems.push(...transformedItems);
      });
      jsonexport(finalTransformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "sku_level_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });
    } catch (error) {
      console.log("Printing error from downloadSKULevelReportAsSpreadsheet.");
      console.error("Error:", error);
    }
  };

  const prepareAndDownloadReportAsSpreadSheet = async () => {
    setShowLoadingPage(true);
    const additionList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadDataForSKULevelReport(
          additionList
        );
      // window.location.reload();

      downloadSKULevelReportAsSpreadsheet(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in prepareAndDownloadReportAsSpreadSheet : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  const prepareAndDownloadPendencyPDF = async () => {
    setShowLoadingPage(true);
    const additionList = allIncomingPurchaseOrders
      .filter((item) => item.is_selected === true) // Use strict equality and ensure the condition is returned
      .map((item) => item.po_number);

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.downloadDataForSKULevelReport(
          additionList
        );
      // window.location.reload();

      // downloadSKULevelReportAsSpreadsheet(response.data);
      setDownloadPendencyPDF(true);
      setPoSkuLevelReportObjectList(response.data);
      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in prepareAndDownloadReportAsSpreadSheet : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
      setShowLoadingPage(false);
    }
  };

  useEffect(() => {
    if (checkedCount == 0) setIsCheckedSelectAll(false);
  }, [checkedCount]);

  return (
    <div className="catalogues-container">
      <div className="catalogues-search-bar-container">
        {/* <CompanyPurchaseOrdersAllPurchaseOrdersSearchBar
          onSearch={handleSearch}
        /> */}

        {checkedCount > 0 && (
          <div>
            <Button
              className="mr-2 filters-button"
              onClick={prepareAndDownloadPendencyPDF}
              variant="outline-primary"
            >
              Download Pendency PDF
            </Button>

            <Button
              className="mr-2 filters-button"
              onClick={prepareAndDownloadReportAsSpreadSheet}
              variant="outline-primary"
            >
              Download SKU Level Report
            </Button>
            <Button
              className="mr-2 filters-button"
              onClick={downloadCurrentViewAsSpreadSheet}
              variant="outline-primary"
            >
              Download PO Level Report
            </Button>
            <Button
              className="mr-2 filters-button"
              onClick={removeSelectedPOsFromWIP}
              variant="outline-primary"
            >
              Remove Selected POs from WIP.
            </Button>
          </div>
        )}
      </div>

      <div className="purchase-orders-table-container">
        <Table bordered hover size="sm">
          <thead className="sticky-header">
            <tr>
              <th>
                {" "}
                <input
                  type="checkbox"
                  checked={isCheckedSelectAll}
                  onChange={handleCheckBoxChangeForAll}
                />
              </th>
              <th>
                PO Number
                {searchQueryGlobal.length == 0 ? (
                  <Filter
                    className="filter-option ml-1"
                    onClick={() => setIsPoNumberFilterVisible(true)}
                  ></Filter>
                ) : (
                  <FilterSquareFill
                    className="filter-option ml-1"
                    style={{ color: "green" }}
                    onClick={() => setIsPoNumberFilterVisible(true)}
                  ></FilterSquareFill>
                )}
                {isPoNumberFilterVisible && (
                  <EscapeKeyListener onEscape={clearPoNumberFilter}>
                    <div className="filter-dropdown">
                      <div className="empty-space"></div>
                      <div className="filter-list-main ml-2 mr-2">
                        <input
                          type="text"
                          placeholder="Search PO Number..."
                          value={searchQueryLocal}
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="filter-buttons-section mt-2 ml-2 mr-2">
                        {/* {poTypeFilterCheckedCount > 0} */}
                        {/* <Button
                          variant="outline-primary"
                          className="filters-button"
                          // onClick={() =>
                          //   setShowSubmitConsignmentItemsModal(true)
                          // }
                        >
                          Clear All
                        </Button> */}

                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={resetPoNumberFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="filters-button"
                          onClick={clearPoNumberFilter}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="filters-button"
                          disabled={searchQueryLocal.length == 0}
                          onClick={applyPONumberFilter}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </EscapeKeyListener>
                )}
              </th>
              <th>PO Type</th>
              <th>Company Name</th>
              <th>Delivery Location</th>
              <th>PO status</th>
              <th>SKU Count</th>
              <th>Demand Quantity</th>
              <th>Dispatched Quantity</th>
              <th>Packed Quantity</th>
              <th>Pending Quantity</th>
              <th>Quantity Fill Rate</th>
              <th>SKU Fill Rate</th>
              <th>Consignment Count</th>
              <th>Boxes Dispatched</th>
              <th>Boxes Packed</th>
              <th>Issue Date</th>
              <th>Expiry Date</th>
              <th>PO addition Date</th>
              <th>PO added By</th>
            </tr>
          </thead>
          <tbody>
            {allIncomingPurchaseOrders.map((purchase_order_item, index) => {
              return (
                <tr className="catalogues-search-bar-row" key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={purchase_order_item.is_selected}
                      onChange={(e) => handleCheckBoxChangeAtIndex(e, index)}
                    />
                  </td>
                  <td className="po-id-link">
                    <a
                      href={`/incomingPos/info/details/${purchase_order_item.po_number}`}
                    >
                      {purchase_order_item.po_number}
                    </a>
                  </td>
                  <td>{purchase_order_item.po_type}</td>
                  <td>{purchase_order_item.company_name}</td>
                  <td>{purchase_order_item.delivery_city}</td>
                  <td
                    className={getCalculatedPoStatusColor(
                      purchase_order_item.calculated_po_status
                    )}
                  >
                    {purchase_order_item.calculated_po_status}
                  </td>

                  <td>{purchase_order_item.analytics_object.sku_count}</td>
                  <td>{purchase_order_item.analytics_object.total_demand}</td>
                  <td>
                    {purchase_order_item.analytics_object.total_dispatched}
                  </td>
                  <td>{purchase_order_item.analytics_object.total_packed}</td>

                  <td>{purchase_order_item.analytics_object.total_pending}</td>
                  <td>
                    {purchase_order_item.analytics_object.quantity_fill_rate}
                  </td>
                  <td>{purchase_order_item.analytics_object.sku_fill_rate}</td>
                  <td>
                    {purchase_order_item.analytics_object.total_consignments}
                  </td>
                  <td>
                    {purchase_order_item.analytics_object.boxes_dispatched}
                  </td>
                  <td>{purchase_order_item.analytics_object.boxes_packed}</td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      purchase_order_item.po_issue_date
                    )}
                  </td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      purchase_order_item.expiry_date
                    )}
                  </td>
                  <td>
                    {DateTimeUtils.formatDateTime(
                      purchase_order_item.created_at
                    )}
                  </td>
                  <td>{purchase_order_item.created_by}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {allIncomingPurchaseOrders.length == 0 && (
          <div className="purchase-orders-table-container no-item">
            {isLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div>No Purchase Orders were found.</div>
            )}
          </div>
        )}
        {searchParams.item_count > 0 &&
          searchParams.item_count < searchParams.expected_item_count && (
            <div className="load-more-catalogues-button-container">
              <Button
                variant="outline-primary"
                className="load-more-catalogues-button"
                onClick={() => loadMore()}
              >
                Load More..
              </Button>
            </div>
          )}
      </div>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
        </div>
      )}

      <Modal
        show={downloadPendencyPDF}
        onHide={() => setDownloadPendencyPDF(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Pendency PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This PDF holds the pendency of selected POs in PDF format.
          <div className="mt-2 mb-2">
            <label>
              <input
                type="checkbox"
                checked={isAddWarehouseInventoryColumnChecked}
                onChange={handleShowWarehouseInventoryCheckboxChange}
              />
              Add Warehouse Inventory Column
            </label>
          </div>
          {poSkuLevelReportObjectList.length > 0 && (
            <PDFDownloadLink
              fileName={`selected_pos_pendency.pdf`}
              document={
                <CompanyPurchaseOrderPendingDispatchMultiPDF
                  showWarehouseInventoryColumn={
                    isAddWarehouseInventoryColumnChecked
                  }
                  createdBy={userDetailsFromStore.user_details.userId}
                  chunkSize={30}
                  poSkuLevelReportObjectList={poSkuLevelReportObjectList}
                />
              }
            >
              {({ blob, url, loading, error }) => (
                <Button className="vendor-action-button" disabled={loading}>
                  {loading ? "Loading document..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CompanyPurchaseOrdersWIPPurchaseOrders;
