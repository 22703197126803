import React, { useState } from "react";
import "./CSS/CompanyPurchaseOrdersCustomDateTimePicker.css";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Button } from "react-bootstrap";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

interface CustomDateTimePickerProps {
  displayText: string;
  buttonText: string;
  onDateTimeChange: (selectedDate: Date | null) => void;
}

const CompanyPurchaseOrdersCustomDateTimePicker: React.FC<
  CustomDateTimePickerProps
> = ({ displayText, buttonText, onDateTimeChange }) => {
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(
    currentDate.getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    currentDate.getMonth()
  );
  const [selectedDay, setSelectedDay] = useState<number | null>(
    currentDate.getDate()
  );

  const handleDateChange = () => {
    if (
      selectedYear !== null &&
      selectedMonth !== null &&
      selectedDay !== null
    ) {
      const newDate = new Date(selectedYear, selectedMonth, selectedDay);

      setSelectedDate(newDate);
      // const durationInMilliseconds = (5 * 60 + 30) * 60 * 1000;
      // const newTimestamp = newDate.getTime() - durationInMilliseconds;
      // onDateTimeChange(new Date(newTimestamp)); //correct UTC time
      onDateTimeChange(newDate);
    }
  };

  const renderYears = () => {
    const years = [];
    for (let year = 2024; year <= 2030; year++) {
      years.push(
        <option key={year} value={year} selected={selectedYear == year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const renderMonths = () => {
    const months = Array.from({ length: 12 }, (_, index) => {
      const month = new Date(0, index);
      return (
        <option key={index} value={index} selected={selectedMonth == index}>
          {month.toLocaleString(undefined, { month: "long" })}
        </option>
      );
    });
    return months;
  };

  const renderDays = () => {
    if (selectedYear !== null && selectedMonth !== null) {
      const lastDay = new Date(selectedYear, selectedMonth + 1, 0).getDate();
      const days = Array.from({ length: lastDay }, (_, index) => (
        <option
          key={index + 1}
          value={index + 1}
          selected={selectedDay == index + 1}
        >
          {index + 1}
        </option>
      ));
      return days;
    }
    return null;
  };

  const renderHours = () => {
    const hours = Array.from({ length: 24 }, (_, index) => (
      <option key={index} value={index}>
        {index.toString().padStart(2, "0")}
      </option>
    ));
    return hours;
  };

  const renderMinutes = () => {
    const minutes = Array.from({ length: 60 }, (_, index) => (
      <option key={index} value={index}>
        {index.toString().padStart(2, "0")}
      </option>
    ));
    return minutes;
  };

  return (
    <div className="date-time-picker-container-main">
      <div className="date-time-picker-selected-date-container ml-1 mb-1">
        <span>{displayText}</span> &nbsp;
        <span>
          <b>
            {selectedDate
              ? DateTimeUtils.formatDateOnly(selectedDate.toLocaleDateString())
              : "    Not Selected"}
          </b>
        </span>
      </div>
      <div className="date-time-picker-container">
        <select
          className="date-time-picker-select"
          onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
        >
          {renderYears()}
        </select>
        <select
          className="date-time-picker-select"
          onChange={(e) => setSelectedMonth(parseInt(e.target.value, 10))}
        >
          {renderMonths()}
        </select>
        <select
          className="date-time-picker-select"
          onChange={(e) => setSelectedDay(parseInt(e.target.value, 10))}
        >
          {renderDays()}
        </select>
      </div>

      <div className="set-expiry-container mt-2">
        <Button
          variant="primary"
          className="date-time-picker-button"
          style={{
            fontSize: "14px",
            backgroundColor: "#f4f4f4",
            color: "black",
          }}
          onClick={handleDateChange}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default CompanyPurchaseOrdersCustomDateTimePicker;
