import React, { useEffect, useState } from "react";
import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import CompanyPurchaseOrdersAdd from "./CompanyPurchaseOrdersAdd";
import CompanyPurchaseOrdersAllPurchaseOrders from "./CompanyPurchaseOrdersAllPurchaseOrders";
import CompanyPurchaseOrdersDetails from "./CompanyPurchaseOrdersDetails";
import "./CSS/CompanyPurchaseOrderDetailHome.css";
import Header from "../Header";
import CompanyPurchaseOrdersPackTaskDetails from "./CompanyPurchaseOrdersConsignmentDetails";
import CompanyPurchaseOrdersDocuments from "./CompanyPurchaseOrdersDocuments";
import CompanyPurchaseOrdersConsignmentDetails from "./CompanyPurchaseOrdersConsignmentDetails";
import CompanyPurchaseOrdersConsignmentDetailsHome from "./CompanyPurchaseOrdersPOConsignmentsHome";
import { ArrowLeft } from "react-bootstrap-icons";
import CompanyPurchaseOrdersLogs from "./CompanyPurchaseOrdersLogs";

interface RouteParams {
  operation: string; // Define the type for the parameter you're expecting
  po_id: string;
}

function CompanyPurchaseOrdersDetailsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    //   return;
    // }
    dispatch(ActiveTab__Action("incomingPos"));
  }, []);

  const { operation, po_id } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(operation);
  const handleItemClick = (item: string) => {
    if (item == "goBack") {
      // history.goBack();
      history.replace("/incomingPos/wipPos");
      return;
    }
    setSelectedItem(item);
    history.replace(`/incomingPos/info/${item}/${po_id}`);
  };

  useEffect(() => {
    setSelectedItem(operation);
  }, [operation]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item `}
                onClick={() => handleItemClick("goBack")}
              >
                <ArrowLeft
                  size={15}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "details" ? "active" : ""
                }`}
                onClick={() => handleItemClick("details")}
              >
                PO Details
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "poConsignments" ? "active" : ""
                }`}
                onClick={() => handleItemClick("poConsignments")}
              >
                PO Consignments
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "documents" ? "active" : ""
                }`}
                onClick={() => handleItemClick("documents")}
              >
                Post Dispatch Documents
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "poLogs" ? "active" : ""
                }`}
                onClick={() => handleItemClick("poLogs")}
              >
                PO Logs
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem === "details" && <CompanyPurchaseOrdersDetails />}
        {selectedItem === "documents" && <CompanyPurchaseOrdersDocuments />}
        {selectedItem === "poConsignments" && (
          <CompanyPurchaseOrdersConsignmentDetailsHome />
        )}
        {selectedItem === "poLogs" && <CompanyPurchaseOrdersLogs />}
      </div>
    </div>
  );
}

export default CompanyPurchaseOrdersDetailsHome;
