import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { PurchaseOrder, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class CompanyPurchaseOrderNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create({
      withCredentials: true,           // Include cookies
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getCompanyNames() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllCompanyNames}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getCompanyNames");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllActiveCompanies() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllCompanies}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from get all active companies");
      console.error("Error:", error);
      throw error;
    }
  }


  async addNewPurchaseOrder(body : any) {
    try {
      let url = `${requestHeaders.rooturl}${requests.addNewIncomingPurchaseOrder}`;
      const response = await this.api.post(url, body, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createNewPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async geturchaseOrderByPOid(po_id : string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getIncomingPurchaseOrderById}${po_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from get purchase order by po id.");
      console.error("Error:", error);
      throw error;
    }
  }

  async submitPurchaseOrderData(jsonData : any[], po_id : string, userId : string){
    try {
      const queryParams: Record<string, any> = {
        created_by : userId, // New page value        
      };
      let url = `${requestHeaders.rooturl}${requests.addNewIncomingPurchaseOrderData}${po_id}`;
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
      url += `?${queryString}`;
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitPurchaseOrderData");
      console.error("Error:", error);
      throw error;
    }
  }

  async deletePartialPurchaseOrder(po_id:string){
    try {      
      let url = `${requestHeaders.rooturl}${requests.deletePartialPurchaseOrderData}${po_id}`;      
      const response = await this.api.delete(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deletePartialPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async checkOpsAndMasterSkuMappingForPurchaseOrder(jsonData : any[]){
    try {   
      let url = `${requestHeaders.rooturl}${requests.checkOpsAndMasterSkuMappingForPurchaseOrder}`;      
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from checkOpsAndMasterSkuMappingForPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async getMasterAndOtherSkuMappingForPurchaseOrder(jsonData : any[]){
    try {   
      let url = `${requestHeaders.rooturl}${requests.getSkuMappingForPurchaseOrder}`;      
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getMasterAndOtherSkuMappingForPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async getSecondaryMasterAndOtherSkuMappingWithMRPVerification(jsonData : any[]){
    try {   
      let url = `${requestHeaders.rooturl}${requests.getSecondaryMasterAndOtherSkuMappingWithMRPVerification}`;      
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getSecondaryMasterAndOtherSkuMappingWithMRPVerification");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllPurchaseOrdersPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
         try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
        }
    }

    async getAllPurchaseOrdersPaginatedWithFilters(
      searchKeyword: string,
      pageNumber: number,
      count: number,
      filters : any
    ) {
          const queryParams: Record<string, any> = {
          page: pageNumber, // New page value
          count: count, // New count value
          };
           try {
          let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPurchaseOrdersPaginatedWithFilters}?search_keyword=${searchKeyword}`;
          if (queryParams) {
              const queryString = Object.keys(queryParams)
              .map((key) => `${key}=${queryParams[key]}`)
              .join("&");
              url += `&${queryString}`;
          }
          const response = await this.api.post(url, filters, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getAllPurchaseOrdersPaginated");
          console.error("Error:", error);
          throw error;
          }
      }

    async getAllPartialPurchaseOrdersPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {
          const queryParams: Record<string, any> = {
          page: pageNumber, // New page value
          count: count, // New count value
          };
           try {
          let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPartialPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
          if (queryParams) {
              const queryString = Object.keys(queryParams)
              .map((key) => `${key}=${queryParams[key]}`)
              .join("&");
              url += `&${queryString}`;
          }
          const response = await this.api.get(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getAllPartialPurchaseOrdersPaginated");
          console.error("Error:", error);
          throw error;
          }
      }

  async getAllWIPPurchaseOrdersPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number,
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        is_wip: "YES"
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllWIPPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
        }
    }


  async getAllPurchaseOrdersListingPaginated(
    po_number: string,
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchIncomingPurchaseOrdersListingPaginated}${po_number}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllPurchaseOrdersListingPaginated");
        console.error("Error:", error);
        throw error;
        }
    }

    async fetchPurchaseOrderDemandObjectDetails(
      po_number: string,      
    ) {         
          try {
          let url = `${requestHeaders.rooturl}${requests.fetchPurchaseOrderDemandObjectDetails}${po_number}`;          
          const response = await this.api.get(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from fetchPurchaseOrderDemandObjectDetails");
          console.error("Error:", error);
          throw error;
          }
      }
  


  async getPurchaseOrderAnalyticsObject(
    po_number: string    
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchPurchaseOrderAnalyticsObject}${po_number}`;        
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getPurchaseOrderAnalyticsObject");
        console.error("Error:", error);
        throw error;
        }
    }

    async acknowledgePurchaseOrder(
      po_number: string ,   
      created_by: string
    ) {        
          try {
          let url = `${requestHeaders.rooturl}${requests.acknowledgePurchaseOrder}${po_number}?created_by=${created_by}`;        
          const response = await this.api.post(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from acknowledgePurchaseOrder");
          console.error("Error:", error);
          throw error;
          }
      }

    async markForWIP(
      po_number: string ,   
      created_by: string
    ) {        
          try {
          let url = `${requestHeaders.rooturl}${requests.markForWIP}${po_number}?created_by=${created_by}`;        
          const response = await this.api.post(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from markForWIP");
          console.error("Error:", error);
          throw error;
          }
      }
    

  async createNewConsignment(
    po_number: string ,   
    created_by: string
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.createConsignment}${po_number}?created_by=${created_by}`;        
        const response = await this.api.post(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from createNewConsignment");
        console.error("Error:", error);
        throw error;
        }
    }

  async fetchConsignmentByConsignmentId(
    consignment_id: string    
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchConsignmentByConsignmentId}${consignment_id}`;        
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchConsignmentByConsignmentId");
        console.error("Error:", error);
        throw error;
        }
    }

  async fetchConsignmentsByPoId(
    po_number: string    
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllConsignmentsByPoId}${po_number}`;        
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchConsignmentsByPoId");
        console.error("Error:", error);
        throw error;
        }
    }
  
  
  async fetchPODocuments(
    po_number: string     
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchPurchaseOrderFiles}${po_number}`; 
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchPODocuments");
        console.error("Error:", error);
        throw error;
        }
      }
 
  async fetchPODetailFiles(
    po_number: string     
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchPurchaseOrderDetailFiles}${po_number}`; 
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchPurchaseOrderDetailFiles");
        console.error("Error:", error);
        throw error;
        }
      }

  async downloadPoFileFromWeb(
    file_id: any
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.downloadPurchaseOrderFile}${file_id}`; 
        const response = await this.api.get(url, { withCredentials: true, responseType:"blob" });        
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from downloadPoFileFromWeb");
        console.error("Error:", error);
        throw error;
        }
      }

  async uploadCompanyPurchaseOrderFiles(
    files : any[], 
    created_by: string,
    po_number: string
  ){
    const formData = new FormData();
    const queryParams: Record<string, any> = {
      created_by : created_by, // New page value        
      po_number : po_number
    };

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    files.forEach((file) => {
      formData.append("files[]", file);
    });

    let url = `${requestHeaders.rooturl}${requests.uploadPurchaseOrderFiles}`;
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    url += `?${queryString}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
      console.log("Printing error from uploadCompanyPurchaseOrderFiles");
      throw error;
    }
  }

  async fetchConsignmentItemsByConsignmentId(consignment_id: string){
    try {
      let url = `${requestHeaders.rooturl}${requests.fetchConsignmentItemsByConsignmentId}${consignment_id}`; 
      const response = await this.api.get(url, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchConsignmentItemsByConsignmentId");
      console.error("Error:", error);
      throw error;
      }
  }

  async submitConsignmentItems(
    consignment_id: string ,   
    new_pack_task_items:any[],
    created_by: string
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.uploadConsignmentItems}${consignment_id}?created_by=${created_by}`;        
        const response = await this.api.post(url, new_pack_task_items, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from submitConsignmentItems");
        console.error("Error:", error);
        throw error;
        }
    }

  async getAllTransporterDetails(){
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllTransporterDetails}`;        
      const response = await this.api.get(url, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllTransporterDetails");
      console.error("Error:", error);
      throw error;
      }
  }

  async markForRTD(po_number:string, consignment_id:string, created_by: string, shipment_details_object:any){
    try {
      let url = `${requestHeaders.rooturl}${requests.markForRTD}${po_number}/${consignment_id}?created_by=${created_by}`;        
      const response = await this.api.post(url,shipment_details_object, { withCredentials: true });
      return response;
      } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markForRTD");
      console.error("Error:", error);
      throw error;
      }
  }

  async fetchAllConsignmentsPaginated(    
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {        
      const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllConsignmentsPaginated}?search_keyword=${searchKeyword}`;        
        if (queryParams) {
          const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
          url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchAllConsignmentsPaginated");
        console.error("Error:", error);
        throw error;
        }
    }
  
  
    async fetchConsignmentsWithPendingInvoices(    
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {        
      const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchConsignmentsWithPendingInvoicesPaginated}?search_keyword=${searchKeyword}`;        
        if (queryParams) {
          const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");
          url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchConsignmentsWithPendingInvoicesPaginated");
        console.error("Error:", error);
        throw error;
        }
    }

  async fetchConsignmentInvoiceEntries(po_id:string, consignment_id:string
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchConsignmentInvoiceEntries}${po_id}/${consignment_id}`;        
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from fetchConsignmentInvoiceEntries");
        console.error("Error:", error);
        throw error;
        }
    }

  async submitInvoiceNumberToWeb(invoice_number: string, po_number: string, consignment_id: string,  created_by: string, invoice_id: string, sold_via: string){
    try {
      const jsonObject: Record<string, any> = {
        invoice_number : invoice_number,
        po_number : po_number,
        consignment_id: consignment_id,
        created_by : created_by, // New page value        
        invoice_id : invoice_id, 
        sold_via: sold_via    
      };
      let url = `${requestHeaders.rooturl}${requests.submitInvoiceNumberToWeb}`;
      
      const response = await this.api.post(url, jsonObject, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitInvoiceNumberToWeb");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async getDataForInvoice(consignment_id: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.getDataForInvoice}${consignment_id}`;      
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDataForInvoice");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async getDataForBoxLabels(consignment_id: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.getDataForBoxLabels}${consignment_id}`;      
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getDataForInvoice");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllConsignmentLogs(consignment_id: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.getAllConsignmentLogs}${consignment_id}`;      
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllConsignmentLogs");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllPurchaseOrderLogs(consignment_id: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.getAllPurchaseOrderLogs}${consignment_id}`;      
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllPurchaseOrderLogs");
      console.error("Error:", error);
      throw error;
    }
  }

  async markPurchaseOrderForCancellation(po_number: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.markPurchaseOrderForCancellation}${po_number}`;      
      const response = await this.api.put(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from markPurchaseOrderForCancellation");
      console.error("Error:", error);
      throw error;
    }
  }

  async removeSelectedPOsFromWIP(removalList: any, created_by: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.removeSelectedPOsFromWIP}?created_by=${created_by}`;      
      const response = await this.api.post(url, removalList, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from removeSelectedPOsFromWIP");
      console.error("Error:", error);
      throw error;
    }
  }

  async addSelectedPOsToWIP(additionList: any, created_by: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.addSelectedPOsToWIP}?created_by=${created_by}`;      
      const response = await this.api.post(url, additionList, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addSelectedPOsToWIP");
      console.error("Error:", error);
      throw error;
    }
  }

  async deleteItemsFromPickList(removalList: any, created_by: string, consignment_id: string){
    try {     
      let url = `${requestHeaders.rooturl}${requests.deleteItemsFromPickList}${consignment_id}?created_by=${created_by}`;      
      const response = await this.api.post(url, removalList, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from deleteItemsFromPickList");
      console.error("Error:", error);
      throw error;
    }
  }


  async downloadDataForSKULevelReport(additionList:any){
    try {     
      let url = `${requestHeaders.rooturl}${requests.downloadDataForSKULevelReport}`;      
      const response = await this.api.post(url, additionList, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from downloadDataForSKULevelReport");
      console.error("Error:", error);
      throw error;
    }
  }

  async uploadInvoice(
    files : any[], 
    created_by: string,
    po_number: string,
    consignment_id: string,
    invoice_id: string,
    invoice_number: string
  ){
    const formData = new FormData();
    const queryParams: Record<string, any> = {
      created_by : created_by, // New page value        
      po_number : po_number,
      consignment_id: consignment_id,
      invoice_id: invoice_id,
      invoice_number: invoice_number
    };

    // Object.values(uploadedImages).forEach((image) => {
    //   formData.append("images[]", image);
    // });

    files.forEach((file) => {
      formData.append("files[]", file);
    });

    let url = `${requestHeaders.rooturl}${requests.uploadInvoiceFiles}`;
    const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
    url += `?${queryString}`;

    try {
      const response = await this.api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.error("Error:", error);
      console.log("Printing error from uploadInvoice");
      throw error;
    }
  }

  async getAllUniqueDeliveryLocations() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllUniqueDeliveryLocations}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from get all delivery locations.");
      console.error("Error:", error);
      throw error;
    }
  }
    
}



export default CompanyPurchaseOrderNetworkUtils;
