import React, { useEffect, useState } from "react";
import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import CompanyPurchaseOrdersAdd from "./CompanyPurchaseOrdersAdd";
import CompanyPurchaseOrdersAllPurchaseOrders from "./CompanyPurchaseOrdersAllPurchaseOrders";
import CompanyPurchaseOrdersDetails from "./CompanyPurchaseOrdersDetails";
import "./CSS/CompanyPurchaseOrderDetailHome.css";
import Header from "../Header";
import CompanyPurchaseOrdersPackTaskDetails from "./CompanyPurchaseOrdersConsignmentDetails";
import CompanyPurchaseOrdersDocuments from "./CompanyPurchaseOrdersDocuments";
import CompanyPurchaseOrdersConsignmentDetails from "./CompanyPurchaseOrdersConsignmentDetails";
import CompanyPurchaseOrdersConsignmentDetailsHome from "./CompanyPurchaseOrdersPOConsignmentsHome";
import CompanyPurchaseOrderConsignmentsAll from "./CompanyPurchaseOrderConsignmentsAll";
import CompanyPurchaseOrderConsignmentsPendingInvoices from "./CompanyPurchaseOrderConsignmentsPendingInvoices";
import { ArrowLeft } from "react-bootstrap-icons";

interface RouteParams {
  category: string;
}

function CompanyPurchaseOrderConsignmentsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    //   return;
    // }
    dispatch(ActiveTab__Action("incomingPos"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    if (item == "goBack") {
      history.goBack();
      // history.replace("/incomingPos/wipPos");
      // window.history.pushState(null, "", "/incomingPos/wipPos");
      // window.location.replace("/incomingPos/wipPos");

      // window.history.go(-(window.history.length - 1));
      return;
    }

    setSelectedItem(item);
    history.replace(`/incomingPos/consignments/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-vendors">
            <ul className="navbar-menu">
              <li
                className={`navbar-item `}
                onClick={() => handleItemClick("goBack")}
              >
                <ArrowLeft
                  size={15}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </li>
              <li
                className={`navbar-item ${
                  selectedItem === "allConsignments" ? "active" : ""
                }`}
                onClick={() => handleItemClick("allConsignments")}
              >
                All consignments
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "pendingInvoices" ? "active" : ""
                }`}
                onClick={() => handleItemClick("pendingInvoices")}
              >
                Pending Invoices
              </li>

              <li
                className={`navbar-item ${
                  selectedItem === "appointments" ? "active" : ""
                }`}
                onClick={() => handleItemClick("appointments")}
              >
                Appointments
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem === "allConsignments" && (
          <CompanyPurchaseOrderConsignmentsAll />
        )}
        {selectedItem === "pendingInvoices" && (
          <CompanyPurchaseOrderConsignmentsPendingInvoices />
        )}
        {/* {selectedItem === "appointments" && (
          <CompanyPurchaseOrdersConsignmentDetailsHome />
        )} */}
      </div>
    </div>
  );
}

export default CompanyPurchaseOrderConsignmentsHome;
