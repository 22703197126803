import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import "./CSS/CompanyPurchaseOrderConsignmentDetails.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  Consignment,
  ConsignmentAnalyticsObject,
  ConsignmentItems,
  ConsignmentItemsBoxView,
  ConsignmentItemsPOView,
  ConsignmentItemsSKUView,
  PoDocument,
  PurchaseOrder,
  PurchaseOrderDemandObject,
  SearchParams,
  ConsignmentDetailed,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Download } from "react-bootstrap-icons";
import jsonexport from "jsonexport";
import Papa from "papaparse";
import _ from "lodash";
import { ElevatorSharp } from "@mui/icons-material";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrderConsignmentsAll() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showBottomLoader, setShowBottomLoader] = useState<boolean>(false);
  const [createNewConsignment, setCreateNewConsignment] = useState(false);
  const [currentConsignments, setCurrentConsignments] = useState<
    ConsignmentDetailed[]
  >([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);

  const fetchAllConsignmentsPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.fetchAllConsignmentsPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          100
        );

      // setCurrentConsignments(response.data);
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentConsignments((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setShowBottomLoader(false);
    } catch (error) {
      console.error("Error in creating new Consignment : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  const handleSearch = (query: string) => {
    setCurrentConsignments([]);
    setShowLoadingPage(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    fetchAllConsignmentsPaginated(query, 1, true);
  };

  const getClassNameForConsignment = (consignment_status: string) => {
    if (consignment_status == "OPEN") return "color-green";
    else if (consignment_status == "CLOSED") return "color-red";
    else if (consignment_status == "MARKED_RTD") return "color-pink";
    return "";
  };

  const getClassNameForInvoiceNumberStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-ASSIGNED") return "color-red";
    else if (invoice_number_status == "ASSIGNED") return "color-green";
    return "";
  };
  const getClassNameForInvoiceUploadStatus = (
    invoice_number_status: string
  ) => {
    if (invoice_number_status == "NOT-UPLOADED") return "color-red";
    else if (invoice_number_status == "UPLOADED") return "color-green";
    return "";
  };

  const loadMore = () => {
    setShowBottomLoader(true);
    fetchAllConsignmentsPaginated("", searchParams.current_page_num + 1, false);
    // setSearchParams((prev) => {
    //   return {
    //     ...prev,
    //     current_page_num: prev.current_page_num + 1,
    //   };
    // });
  };

  useEffect(() => {
    setShowLoadingPage(true);
    fetchAllConsignmentsPaginated("", 1, false);
  }, []);

  return (
    <div className="all-consignments-container">
      {currentConsignments && (
        <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-container">
          {currentConsignments.length === 0 ? (
            <div className="po-document-section-no-document mt-3">
              No Consignment was found
            </div>
          ) : (
            <div className="all-consignments-table mt-3">
              <div className="consignments-count-container">
                Showing {searchParams.item_count} of{" "}
                {searchParams.expected_item_count} consignment(s).
              </div>
              <Table bordered hover size="sm" className="table-auto-width">
                <thead>
                  <tr>
                    <th>Consignment ID</th>
                    <th>Company Name</th>
                    <th>Location</th>
                    <th>Sold Via</th>
                    <th>PO Number</th>
                    <th>PO Type</th>
                    <th>Consignment Status</th>
                    <th>Invoice Number Status</th>
                    <th>Invoice Number</th>
                    <th>Invoice Upload Status</th>
                    <th>Boxes Count</th>
                    <th>Sku Count</th>
                    <th>Total Quantity</th>
                    <th>Transporter Name</th>
                    <th>Vehicle Number/Docket Number</th>
                    <th>Consignment Created At</th>
                    <th>Consignment Marked RTD At</th>
                    <th>Marked RTD By</th>
                  </tr>
                </thead>
                <tbody>
                  {currentConsignments.map((Consignment, index) => {
                    return (
                      <tr className="catalogues-search-bar-row">
                        <td className="po-id-link">
                          <a
                            href={`/incomingPos/info/consignmentDetails/${Consignment.po_number}/${Consignment.consignment_id}/documents`}
                          >
                            {Consignment.consignment_id}
                          </a>
                        </td>
                        <td>{Consignment.company_name}</td>
                        <td>{Consignment.delivery_city}</td>
                        <td>{Consignment.sold_via}</td>
                        <td>{Consignment.po_number}</td>
                        <td>{Consignment.po_type}</td>
                        <td
                          className={getClassNameForConsignment(
                            Consignment.consignment_status
                          )}
                        >
                          {Consignment.consignment_status}
                        </td>
                        <td
                          className={getClassNameForInvoiceNumberStatus(
                            Consignment.invoice_number_status
                          )}
                        >
                          {Consignment.invoice_number_status}
                        </td>
                        <td>{Consignment.invoice_number}</td>

                        <td
                          className={getClassNameForInvoiceUploadStatus(
                            Consignment.invoice_upload_status
                          )}
                        >
                          {Consignment.invoice_number_status == "NOT-ASSIGNED"
                            ? ""
                            : Consignment.invoice_upload_status}
                        </td>

                        <td>{Consignment.total_boxes}</td>
                        <td>{Consignment.total_skus}</td>
                        <td>{Consignment.total_quantity}</td>
                        <td>{Consignment.transporter_name}</td>
                        <td>{Consignment.vehicle_number}</td>

                        <td>
                          {DateTimeUtils.formatDateTime(Consignment.created_at)}
                        </td>

                        <td>
                          {DateTimeUtils.formatDateTime(
                            Consignment.marked_rtd_at
                          )}
                        </td>

                        <td>{Consignment.marked_rtd_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {searchParams.item_count > 0 &&
                searchParams.item_count < searchParams.expected_item_count &&
                !showBottomLoader && (
                  <div className="load-more-catalogues-button-container">
                    <Button
                      variant="outline-primary"
                      className="load-more-consignments-button"
                      onClick={() => loadMore()}
                    >
                      Load More..
                    </Button>
                  </div>
                )}
              {showBottomLoader && (
                <div className="bottom-progress-container">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}

      {showDownloadPage && (
        <div className="loading-page-download">
          <Spinner animation="grow" />
          Please wait, While your file is being downloaded...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrderConsignmentsAll;
