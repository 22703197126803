import React, { useEffect, useState } from "react";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ManufacturingHubHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    //   return;
    // }
    dispatch(ActiveTab__Action("manufacturingHub"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);

    if (item == "consignments") {
      history.push(`/incomingPos/${item}/allConsignments`);
    } else {
      history.replace(`/incomingPos/${item}`);
    }
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <div className="nav-container">
        <nav className="navbar-vendors">
          <ul className="navbar-menu">
            <li
              className={`navbar-item ${
                selectedItem === "regularDemand" ? "active" : ""
              }`}
              onClick={() => handleItemClick("regularDemand")}
            >
              Regular Demand
            </li>

            <li
              className={`navbar-item ${
                selectedItem === "poDemand" ? "active" : ""
              }`}
              onClick={() => handleItemClick("poDemand")}
            >
              Purchase Order's Demand
            </li>
          </ul>
        </nav>
      </div>

      {/* {selectedItem == "all" && <PurchaseOrdersOpen />}
      {selectedItem == "listing-view" && <PurchaseOrdersListingView />}
      {selectedItem == "add" && <PurchaseOrdersAdd />} */}

      {/* {selectedItem === "addPo" && <CompanyPurchaseOrdersAdd />}
      {selectedItem === "wipPos" && <CompanyPurchaseOrdersWIPPurchaseOrders />}
      {selectedItem === "allPos" && <CompanyPurchaseOrdersAllPurchaseOrders />}
      {selectedItem === "partiallyCreatedPos" && (
        <CompanyPurchaseOrdersPartiallyCreatedPurchaseOrders />
      )}
      {selectedItem === "planPos" && <CompanyPurchaseOrdersPlanner />} */}
      {/* {selectedItem === "dispatchedConsignments" && (
        <CompanyPurchaseOrderConsignmentsDispatchedHome />
      )} */}
    </div>
  );
}

export default ManufacturingHubHome;
