import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersAllPurchaseOrders.css";

import {
  Catalogue,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  PurchaseOrder,
  PurchaseOrderLogs,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";

interface RouteParams {
  po_id: string;
  consignment_id: string;
}

function CompanyPurchaseOrdersConsignmentLogs() {
  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  let pageNumber = 1;
  const { po_id, consignment_id } = useParams<RouteParams>();

  const [consignmentLogs, setConsignmentLogs] = useState<PurchaseOrderLogs[]>(
    []
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [isLoading, setIsLoading] = useState(true);

  const getAllConsignmentLogs = async (consignmentId: string) => {
    setIsLoading(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllConsignmentLogs(
          consignmentId
        );

      setConsignmentLogs(response.data);
    } catch (error) {
      console.error("Error in fetching catalogues :", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllConsignmentLogs(consignment_id);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPColor = (wipStatus: string) => {
    if (wipStatus === "YES") return "incoming-po-text-green";
    else return "incoming-po-text-red";
  };

  return (
    <div className="catalogues-container">
      <div className="vendor-action-buttons-container incoming-purchase-order-pack-task-details-table-container">
        <div className="po-document-section-heading">
          <Table bordered hover size="sm" className="table-auto-width mt-2">
            <tbody>
              <tr className="consignment-heading">
                <td className="nowrap">Consignment - {consignment_id}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      {consignmentLogs.length > 0 ? (
        <div className="purchase-orders-table-container">
          <Table bordered hover size="sm">
            <thead className="sticky-header">
              <tr>
                <th>Log ID</th>
                <th>Operation Performed</th>
                <th>PO Number</th>
                <th>Consignment ID</th>
                <th>Foreign Key</th>
                <th>Remarks</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {consignmentLogs.map((logItem, index) => {
                return (
                  <tr className="catalogues-search-bar-row" key={index}>
                    <td>{logItem.id}</td>
                    <td>{logItem.operation_performed}</td>
                    <td>{logItem.po_number}</td>
                    <td>{logItem.consignment_id}</td>
                    <td>{logItem.foreign_key}</td>
                    <td>{logItem.remarks}</td>
                    <td>{logItem.created_by}</td>
                    <td> {DateTimeUtils.formatDateTime(logItem.created_at)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No Logs were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersConsignmentLogs;
