import React, { useEffect, useState } from "react";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import CompanyPurchaseOrdersAdd from "./CompanyPurchaseOrdersAdd";
import CompanyPurchaseOrdersAllPurchaseOrders from "./CompanyPurchaseOrdersAllPurchaseOrders";
import CompanyPurchaseOrdersWIPPurchaseOrders from "./CompanyPurchaseOrdersWIPPurchaseOrders";
import CompanyPurchaseOrdersPartiallyCreatedPurchaseOrders from "./CompanyPurchaseOrdersPartiallyCreatedPurchaseOrders";
// import PurchaseOrdersAdd from "./PurchaseOrdersAdd";
// import PurchaseOrdersOpen from "./PurchaseOrdersOpen";
// import PurchaseOrdersListingView from "./PurchaseOrdersListingView";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function CompanyPurchaseOrdersPlanner() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    // if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
    //   history.push(`/listings/all`);
    //   return;
    // }
    dispatch(ActiveTab__Action("incomingPos"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/incomingPos/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div>
      <ul>
        <li>
          Add option to create custom lists of POs. Here the user can create a
          temporary list and add POs there.{" "}
        </li>
        <li>
          Add option to create weekly list, where week days will be displayed
          and available POs can be planned out over them.{" "}
        </li>
        <li>
          Every PO in here will have separate analytics and all that grouping
          options.
        </li>
      </ul>
    </div>
  );
}

export default CompanyPurchaseOrdersPlanner;
