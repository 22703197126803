import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Company, Vendor } from "../../atomic_components/types";

interface POTypeDropdownProps {
  placeHolder: string;
  options: string[];
  onItemSelected: (value: string) => void;
  providedSelectedValue: string | undefined;
}

const CompanyPurchaseOrdersPoTypeDropDown: React.FC<POTypeDropdownProps> = ({
  placeHolder,
  options,
  onItemSelected,
  providedSelectedValue,
}) => {
  const [filter, setFilter] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    providedSelectedValue
  );

  useEffect(() => {
    setSelectedValue(providedSelectedValue);
  }, [providedSelectedValue]);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSelect = (value: string | null) => {
    console.log("logging selected option ", value);
    if (value) {
      options.forEach((option) => {
        if (option === value) {
          setSelectedValue(option);
          onItemSelected(option);
        }
      });
    }
  };

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        className="custom-dropdown-toggle"
        style={{
          width: "100%",
          fontSize: "14px",
          backgroundColor: "#f4f4f4",
          color: "black",
        }}
      >
        {(selectedValue && `${selectedValue}`) || `${placeHolder}`}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
      >
        <input
          type="text"
          className="form-control mb-2"
          placeholder="Filter options..."
          value={filter}
          style={{ width: "100%" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter(e.target.value)
          }
        />
        {filteredOptions.map((option, index) => (
          <Dropdown.Item key={index} eventKey={option as any}>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CompanyPurchaseOrdersPoTypeDropDown;
