import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  IncomingPurchaseOrder,
  IncomingPurchaseOrderListingDetailsDataObject,
  POSkuLevelReportObject,
  PacksAndCombos,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { grey } from "@mui/material/colors";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 50, // Adjusted for multi-line header
    paddingBottom: 30, // Space for footer
    paddingHorizontal: 10,
  },
  section: {
    margin: 10,
    padding: 0, // No padding for the section
    flexGrow: 1,
  },
  containerAllBorder: {
    flexDirection: "row",
    // justifyContent: "space-between",
    border: "1px solid black",
    fontSize: 8,
    textAlign: "center", // Center text for header row
  },
  containerBottomBorder: {
    flexDirection: "row",
    // justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    fontSize: 8,
    padding: 0, // No padding for the data rows
    textAlign: "center", // Center text for data rows
  },
  srNo: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  srNoHeading: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSkuHeading: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSku: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimary: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimaryHeading: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  warehouseInventory: {
    width: "10%", // Adjusted width`
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
    backgroundColor: "#f4f4f4",
  },
  warehouseInventoryHeading: {
    width: "10%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 8,
    paddingTop: 8,
  },
  demand: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 3,
    paddingTop: 3,
    textAlign: "center", // Center text
  },
  pendingDemandHeading: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  smallTextSubHeading: {
    fontSize: 6,
  },
  emptyColumn: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  emptyColumnHeading: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  emptyColumnHeadingSecondary: {
    width: "30%", // Added column with some width
    padding: 0, // No padding for the empty column
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  emptyColumnSecondary: {
    width: "30%", // Added column with some width
    padding: 0, // No padding for the empty column
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  headerContainer: {
    textAlign: "center", // Center all text in header
    marginBottom: 10, // Adjust spacing between header lines
  },
  headerTextBold: {
    fontSize: 14,
    fontWeight: "bold", // Bold text for company name
    textAlign: "center", // Center company name
    marginBottom: 20,
  },
  headerText: {
    fontSize: 10,
    textAlign: "center", // Center other lines
  },
  headerTextQty: {
    fontSize: 10,
    textAlign: "center", // Center other lines
    marginTop: 10,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center", // Center text for footer
    fontSize: 10,
    padding: 10, // Keep padding for the footer text
  },
  totalSkusPendingContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 10,
    fontSize: 10,
  },
  totalSkusText: {
    fontWeight: "bold",
    marginRight: 20, // Space between total SKUs and pending quantity
  },
  totalPendingText: {
    fontWeight: "bold",
  },
  smallText: {
    fontSize: 8,
    textAlign: "right", // Align text to the right
    marginRight: 12,
  },
});

const getAvailableQuantityForMulti = (
  packsAndCombos: PacksAndCombos[]
): number => {
  // console.log(listings);
  let available_quantity = 10000000;
  packsAndCombos.forEach((packsAndCombo, index) => {
    available_quantity = Math.min(
      available_quantity,
      packsAndCombo.listing.available_quantity
    );
  });

  return available_quantity == 10000000 ? 0 : available_quantity;
};

const chunkArray = (
  array: IncomingPurchaseOrderListingDetailsDataObject[],
  size: number
): IncomingPurchaseOrderListingDetailsDataObject[][] => {
  // const sortedArray = [...array].sort((a, b) => {
  //   const diffA = a.demand - 0; //a.pending;
  //   const diffB = b.demand - 0; //b.pending;
  //   return diffB - diffA; // descending order
  // });

  const sortedArray = [...array].sort((a, b) => {
    const diffA = a.demand - a.reserved_quantity - a.dispatched_quantity; //a.pending;
    const diffB = b.demand - b.reserved_quantity - b.dispatched_quantity; //b.pending;
    return diffB - diffA; // descending order
  });

  const finalArray = sortedArray.filter(
    (item) =>
      item.demand - item.dispatched_quantity - item.reserved_quantity > 0
  );

  return Array.from(
    { length: Math.ceil(finalArray.length / size) },
    (_, index) => finalArray.slice(index * size, index * size + size)
  );
};

const CompanyPurchaseOrderPendingDispatchMultiPDF: React.FC<{
  showWarehouseInventoryColumn: boolean;
  createdBy: String;
  chunkSize: number;
  poSkuLevelReportObjectList: POSkuLevelReportObject[];
}> = ({
  showWarehouseInventoryColumn,
  createdBy,
  chunkSize,
  poSkuLevelReportObjectList,
}) => {
  return (
    <Document>
      {poSkuLevelReportObjectList.map((poSkuLevelReportObject, main_index) => {
        const chunkedArray = chunkArray(
          poSkuLevelReportObject.listings,
          chunkSize
        );

        return chunkedArray.map(
          (currentPurchaseOrderListingDetails, chunkedIndex) => (
            <Page size="A4" style={styles.page}>
              <View style={styles.headerContainer}>
                {chunkedIndex === 0 ? ( // Only for the first page
                  <>
                    <Text style={styles.headerTextBold}>
                      {poSkuLevelReportObject.company.company_name}
                    </Text>
                    <Text style={styles.headerText}>
                      PO Number: {poSkuLevelReportObject.po_number} | Location:{" "}
                      {poSkuLevelReportObject.delivery_city}
                    </Text>
                    <Text style={styles.headerText}>
                      PO Issue Date:{" "}
                      {DateTimeUtils.formatDateOnly(
                        poSkuLevelReportObject.po_issue_date
                      )}{" "}
                      | Expiry Date:{" "}
                      {DateTimeUtils.formatDateOnly(
                        poSkuLevelReportObject.expiry_date
                      )}
                    </Text>
                    <Text style={styles.headerText}>
                      PDF created by: {createdBy} | PDF created at:{" "}
                      {DateTimeUtils.getCurrentDateTime()}
                    </Text>
                    <Text style={styles.headerTextQty}>
                      Total SKUs: {poSkuLevelReportObject.listings.length} |
                      Original Demand:{" "}
                      {poSkuLevelReportObject.listings.reduce(
                        (total, cp) => total + cp.demand,
                        0
                      )}
                    </Text>
                    <Text style={styles.headerText}>
                      Pending SKUs:{" "}
                      {
                        poSkuLevelReportObject.listings.filter(
                          (item) =>
                            item.demand -
                              item.reserved_quantity -
                              item.dispatched_quantity >
                            0
                        ).length
                      }{" "}
                      | Pending Demand:{" "}
                      {poSkuLevelReportObject.listings
                        .filter(
                          (item) =>
                            item.demand -
                              item.reserved_quantity -
                              item.dispatched_quantity >
                            0
                        )
                        .reduce(
                          (total, item) =>
                            total +
                            (item.demand -
                              item.reserved_quantity -
                              item.dispatched_quantity),
                          0
                        )}
                    </Text>
                  </>
                ) : (
                  // For other pages, show minimal info
                  <>
                    <Text style={styles.smallText}>
                      {poSkuLevelReportObject.company.company_name}
                    </Text>
                    <Text style={styles.smallText}>
                      PO Number: {poSkuLevelReportObject.po_number}
                    </Text>
                    <Text style={styles.smallText}>
                      Location: {poSkuLevelReportObject.delivery_city}
                    </Text>
                  </>
                )}
              </View>

              {/* Body */}
              <View style={styles.section}>
                <View style={styles.containerAllBorder}>
                  <View style={styles.srNoHeading}>
                    <Text>#</Text>
                  </View>
                  <View style={styles.poSkuHeading}>
                    <Text>PO SKU</Text>
                  </View>
                  <View style={styles.companyCodePrimaryHeading}>
                    <Text>Company Code Primary</Text>
                  </View>

                  {showWarehouseInventoryColumn && (
                    <View style={styles.warehouseInventoryHeading}>
                      <Text>Warehouse</Text>
                      <Text>Inventory</Text>
                    </View>
                  )}
                  <View style={styles.pendingDemandHeading}>
                    <Text>Pending</Text>
                    <Text>Demand</Text>
                    <Text style={styles.smallTextSubHeading}>
                      (Demand - (Packed + Dispatched))
                    </Text>
                  </View>

                  {showWarehouseInventoryColumn ? (
                    <View style={styles.emptyColumnHeading}>
                      <Text> </Text> {/* Empty column */}
                    </View>
                  ) : (
                    <View style={styles.emptyColumnHeadingSecondary}>
                      <Text> </Text> {/* Empty column */}
                    </View>
                  )}
                </View>

                {currentPurchaseOrderListingDetails.map((singleItem, index) => (
                  <View key={index} style={styles.containerBottomBorder}>
                    <View style={styles.srNo}>
                      <Text>{chunkedIndex * chunkSize + index + 1}</Text>
                    </View>
                    <View style={styles.poSku}>
                      <Text>{singleItem.po_secondary_sku}</Text>
                    </View>
                    <View style={styles.companyCodePrimary}>
                      <Text>{singleItem.company_code_primary}</Text>
                    </View>
                    {showWarehouseInventoryColumn && (
                      <View style={styles.warehouseInventory}>
                        <Text>
                          {singleItem.sku_type === "MULTI"
                            ? getAvailableQuantityForMulti(
                                singleItem.multi_listings
                              )
                            : singleItem.listing
                            ? singleItem.listing.available_quantity
                            : 0}
                        </Text>
                      </View>
                    )}
                    <View style={styles.demand}>
                      <Text>
                        {singleItem.demand -
                          singleItem.dispatched_quantity -
                          singleItem.reserved_quantity}
                      </Text>
                    </View>
                    {showWarehouseInventoryColumn ? (
                      <View style={styles.emptyColumn}>
                        <Text> </Text> {/* Empty column */}
                      </View>
                    ) : (
                      <View style={styles.emptyColumnSecondary}>
                        <Text> </Text> {/* Empty column */}
                      </View>
                    )}
                  </View>
                ))}
              </View>

              {/* Footer */}
              <Text
                style={styles.footer}
                render={({ pageNumber, totalPages }) =>
                  `Page ${chunkedIndex + 1} of ${chunkedArray.length}`
                }
              />
            </Page>
          )
        );
      })}
    </Document>
  );
};

export default CompanyPurchaseOrderPendingDispatchMultiPDF;
