import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class VendorsNetworkUtil {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location: any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create({
      withCredentials: true,           // Include cookies
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getAllVendors() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllVendors}`;
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getAllVendors");
      console.error("Error:", error);
      throw error;
    }
  }

  async getVendorByVendorId(vendor_id:string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getVendorByVendorId}${vendor_id}`;
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getVendorByVendorId");
      console.error("Error:", error);
      throw error;
    }
  }

  async getVendorListingsByVendorId(vendor_id:string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getVendorListingsByVendorId}${vendor_id}`;
      const response = await this.api.get(url, { withCredentials: true });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getVendorListingsByVendorId");
      console.error("Error:", error);
      throw error;
    }
  }

  async updateVendorDetailsByVendorId(vendor_id: string, updatedVendor: Vendor) {
    try {
      let url = `${requestHeaders.rooturl}${requests.updateVendorByVendorId}${vendor_id}`;
      const response = await this.api.put(url, updatedVendor, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateVendorDetailsByVendorId");
      console.error("Error:", error);
      throw error;
    }
  }

  async updateVendorSpecialtyByVendorId(vendor_id: string, specialties: Specialty[]) {
    try {
      let url = `${requestHeaders.rooturl}${requests.updateVendorSpecialtiesByVendorId}${vendor_id}`;
      const response = await this.api.put(url, specialties, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateVendorSpecialtyByVendorId");
      console.error("Error:", error);
      throw error;
    }
  }

  async updateCostPriceyByVendorIdAndSkuId(new_vendor:VendorListing|undefined, vendor_id: number | undefined, sku_id:string|undefined) {
    try {
      let url = `${requestHeaders.rooturl}${requests.updateCostPriceyByVendorIdAndSkuId}${vendor_id}/${sku_id}`;
      const response = await this.api.put(url, new_vendor, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from updateCostPriceyByVendorIdAndSkuId");
      console.error("Error:", error);
      throw error;
    }
  }

  async mapNewItemToVendor(new_mapping:VendorListing, vendor_id: string | undefined, sku_id:string|undefined) {
    try {
      let url = `${requestHeaders.rooturl}${requests.mapNewItemToVendor}${vendor_id}/${sku_id}`;
      const response = await this.api.post(url, new_mapping, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from mapNewItemToVendor");
      console.error("Error:", error);
      throw error;
    }
  }
  
  async removeItemFromVendor(vendor_id: string, sku_id:string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.removeItemFromVendor}${vendor_id}/${sku_id}`;
      const response = await this.api.post(url, {}, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      console.log("Printing error from removeItemFromVendor");
      console.error("Error:", error);
      throw error;
    }
  }

  async getCostPriceTimeLine(vendor_id: number | undefined, sku_id:string|undefined) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getCostPriceTimeline}${vendor_id}/${sku_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getCostPriceTimeLine");
      console.error("Error:", error);
      throw error;
    }
  }

  async fetchAssociatedVendors(sku_id:string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.fetchAssociatedVendors}${sku_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from fetchAssociatedVendors");
      console.error("Error:", error);
      throw error;
    }
  }

  async addNewVendor(vendor : any){
    try {
      let url = `${requestHeaders.rooturl}${requests.addNewVendor}`;
      const response = await this.api.post(url, vendor, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from addNewVendor");
      console.error("Error:", error);
      throw error;
    }
  }

}

export default VendorsNetworkUtil;
